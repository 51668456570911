/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen008.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/admin'
        title='Adminstartor'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/finance/fin001.svg'
      >
        <AsideMenuItem
          to='/apps/admin/school'
          icon='/media/icons/duotune/general/gen022.svg'
          title='School'
        />
        <AsideMenuItem
          to='/apps/admin/academic-year'
          title='Academic Year'
          icon='/media/icons/duotune/general/gen022.svg'
        />
        <AsideMenuItem
          to='/apps/admin/role'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Role'
        />
        <AsideMenuItem
          to='/apps/admin/permission'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Permission'
        />
        <AsideMenuItem
          to='/apps/admin/account'
          icon='/media/icons/duotune/general/gen022.svg'
          title='User'
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/apps/employee-management'
        title='Employee Management'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com014.svg'
      >
        <AsideMenuItem
          to='/apps/employee-management/employee'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Employee'
        />
        <AsideMenuItem
          to='/apps/employee-management/employee-attendance'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Employee Attendance'
        />
        <AsideMenuItem
          to='/apps/employee-management/department'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Department'
        />
        <AsideMenuItem
          to='/apps/employee-management/designation'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Designation'
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/apps/academic'
        title='Academic'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/finance/fin006.svg'
      >
        <AsideMenuItem
          to='/apps/academic/class'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Classes'
        />
        <AsideMenuItem
          to='/apps/academic/section'
          title='Section'
          icon='/media/icons/duotune/general/gen022.svg'
        />
        <AsideMenuItem
          to='/apps/academic/subject'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Subject'
        />
        <AsideMenuItem
          to='/apps/academic/syllabus'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Syllabus'
        />
        <AsideMenuItem
          to='/apps/academic/study-material'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Study material'
        />
        <AsideMenuItem
          to='/apps/academic/class-lecture'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Class lecture'
        />
        <AsideMenuItem
          to='/apps/academic/assignment'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Assignment'
        />
        <AsideMenuItem
          to='/apps/academic/assignment-submission'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Assignment Submission'
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/apps/student-management'
        title='Student Management'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com006.svg'
      >
        <AsideMenuItem
          to='/apps/student-management/student'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Student'
        />
        <AsideMenuItem
          to='/apps/student-management/student-attendance'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Student Attendance'
        />
        <AsideMenuItem
          to='/apps/student-management/student-type'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Student Type'
        />
        <AsideMenuItem
          to='/apps/student-management/admission'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Online Admission'
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/apps/id-cards'
        title='ID Card'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/technology/teh002.svg'
      >
        <AsideMenuItem
          to='/apps/id-cards/student-id-card'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Student ID Card'
        />
        <AsideMenuItem
          to='/apps/id-cards/employee-id-card'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Employee ID Card'
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/apps/exam'
        title='Exam'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/general/gen055.svg'
      >
        <AsideMenuItem
          to='/apps/exam/exam'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Exam'
        />
        <AsideMenuItem
          to='/apps/exam/schedule'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Schedule'
        />
        <AsideMenuItem
          to='/apps/exam/grade'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Grade'
        />
        <AsideMenuItem
          to='/apps/exam/exam-attendance'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Attendance'
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/apps/mark'
        title='Exam Mark'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/ecommerce/ecm011.svg'
      >
        <AsideMenuItem
          to='/apps/mark'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Mark'
        />
        <AsideMenuItem
          to='/apps/marksheet'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Marksheet'
        />
        <AsideMenuItem
          to='/apps/psychomotor/marksheet'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Psychomotor'
        />
        <AsideMenuItem
          to='/apps/examresult'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Exam Term Result'
        />
        {/* <AsideMenuItem
          to='/apps/finalresult'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Exam Final Result'
        /> */}
        <AsideMenuItem
          to='/apps/resultcard'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Result Card'
        />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/apps/promotion'
        icon='/media/icons/duotune/arrows/arr056.svg'
        title='Promotion'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItemWithSub
        to='/apps/asset'
        title='Asset Management'
        icon='/media/icons/duotune/general/gen019.svg'
      >
        <AsideMenuItem
          to='/apps/category'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Asset Categories'
          fontIcon='bi-layers'
        />
        <AsideMenuItem
          to='/apps/store'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Asset Store'
          fontIcon='bi-layers'
        />
        <AsideMenuItem
          to='/apps/item'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Asset Items'
          fontIcon='bi-layers'
        />
        <AsideMenuItem
          to='/apps/purchase'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Asset Purchase'
          fontIcon='bi-layers'
        />
        <AsideMenuItem
          to='/apps/todo'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Asset Issue & Return'
          fontIcon='bi-layers'
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/apps/expenditrue'
        title='Expenditures'
        icon='/media/icons/duotune/graphs/gra003.svg'
      >
        <AsideMenuItem
          to='/apps/expenditure/exphead'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Expenditure Head'
          fontIcon='bi-layers'
        />
        <AsideMenuItem
          to='/apps/expenditure'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Expenditure'
          fontIcon='bi-layers'
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/apps/leave'
        title='Leave'
        icon='/media/icons/duotune/general/gen009.svg'
      >
        <AsideMenuItem
          to='/apps/application'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Leave Application'
          fontIcon='bi-layers'
        />
        <AsideMenuItem
          to='/apps/application/type'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Leave Type'
          fontIcon='bi-layers'
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/apps/Complains'
        title='Complain'
        icon='/media/icons/duotune/general/gen009.svg'
      >
        <AsideMenuItem
          to='/apps/complain/type'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Complain Type'
          fontIcon='bi-layers'
        />
        <AsideMenuItem
          to='/apps/complain'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Complains'
          fontIcon='bi-layers'
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/apps/library-member'
        title='Library'
        icon='/media/icons/duotune/files/fil002.svg'
      >
        <AsideMenuItem
          to='/apps/library-member'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Library Member'
          fontIcon='bi-layers'
        />
        <AsideMenuItem
          to='/apps/book'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Book'
          fontIcon='bi-layers'
        />
        <AsideMenuItem
          to='/apps/book/issue'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Issue & Return'
          fontIcon='bi-layers'
        />
        <AsideMenuItem
          to='/apps/ebook'
          icon='/media/icons/duotune/general/gen022.svg'
          title='E~Book'
          fontIcon='bi-layers'
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/apps/hostel'
        title='Hostel'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/general/gen001.svg'
      >
        <AsideMenuItem
          to='/apps/member'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Hostel Member'
          fontIcon='bi-layers'
        />
        <AsideMenuItem
          to='/apps/hostel'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Hostel'
          fontIcon='bi-layers'
        />
        <AsideMenuItem
          to='/apps/room'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Rooms'
          fontIcon='bi-layers'
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/apps/frontend'
        title='Manage Frontend'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/general/gen001.svg'
      >
        <AsideMenuItem
          to='/apps/frontend/slider'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Slider'
        />
        <AsideMenuItem
          to='/apps/frontend/about'
          icon='/media/icons/duotune/general/gen022.svg'
          title='About Us'
        />
        <AsideMenuItem
          to='/apps/frontend/news'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Announcement'
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/apps/gallery'
        title='Gallery'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/general/gen006.svg'
      >
        <AsideMenuItem
          to='/apps/gallery'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Gallery'
        />
        <AsideMenuItem
          to='/apps/gallery/image'
          icon='/media/icons/duotune/general/gen022.svg'
          title='Gallery Images'
        />
      </AsideMenuItemWithSub>
    </>
  )
}
