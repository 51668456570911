import {lazy, FC, Suspense, useEffect} from 'react'
import {Route, Routes, Navigate, useLocation} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {getTitle} from '../../_component/CustomFunctions'
import {useAuth} from '../modules/auth'

const PrivateRoutes = () => {
  const {privilege} = useAuth()
  useEffect(() => {
    const sideMenu = document.getElementById('#kt_aside_menu')
    const sideMenuLink = sideMenu?.querySelectorAll('a.menu-link')
    Array.from(sideMenuLink || []).forEach((col: any) => {
      const titles = col.href.split('/')
      const title = titles[titles.length - 1]
      const p = privilege?.find((p) => p.operation?.operation_slug === title)
      if (p?.is_view !== true && col.href !== window.location.origin + '/dashboard')
        col.parentElement.classList.add('d-none')
      else col.parentElement.classList.remove('d-none')
    })
    const sideMenuItem = sideMenu?.querySelectorAll('.menu-item.menu-accordion')
    Array.from(sideMenuItem || []).forEach((col: any) => {
      const subCount = col.querySelector('.menu-sub').childElementCount
      const subChildCount = col.querySelectorAll('.menu-sub>.d-none')?.length
      if (subCount <= subChildCount) col.classList.add('d-none')
      else col.classList.remove('d-none')
    })
  }, [privilege])

  const AccountPage = lazy(() => import('../modules/apps/profile/AccountPage'))
  const AcademicYearsPage = lazy(() => import('../modules/apps/academic-year/AcademicYearsPage'))
  const AccountsPage = lazy(() => import('../modules/apps/account/AccountsPage'))
  const AdmissionsPage = lazy(() => import('../modules/apps/admission/AdmissionsPage'))
  const AssetCategorysPage = lazy(() => import('../modules/apps/asset-category/AssetCategorysPage'))
  const AssetItemsPage = lazy(() => import('../modules/apps/asset-item/AssetItemsPage'))
  const AssetIssuesPage = lazy(() => import('../modules/apps/asset-issue/AssetIssuesPage'))
  const AssetPurchasesPage = lazy(() => import('../modules/apps/asset-purchase/AssetPurchasesPage'))
  const AssetStoresPage = lazy(() => import('../modules/apps/asset-store/AssetStoresPage'))
  const AssignmentSubmissionsPage = lazy(
    () => import('../modules/apps/assignment-submission/AssignmentSubmissionsPage')
  )
  const AssignmentsPage = lazy(() => import('../modules/apps/assignment/AssignmentsPage'))
  const BooksPage = lazy(() => import('../modules/apps/book/BooksPage'))
  const ClasssPage = lazy(() => import('../modules/apps/class/ClasssPage'))
  const ComplainsPage = lazy(() => import('../modules/apps/complain/ComplainsPage'))
  const DepartmentsPage = lazy(() => import('../modules/apps/department/DepartmentsPage'))
  const DesignationsPage = lazy(() => import('../modules/apps/designation/DesignationsPage'))
  const EbooksPage = lazy(() => import('../modules/apps/ebook/EbooksPage'))
  const EmployeeAttendancesPage = lazy(
    () => import('../modules/apps/employee-attendance/EmployeeAttendancesPage')
  )
  const EmployeesPage = lazy(() => import('../modules/apps/employee/EmployeesPage'))
  const ExamAttendancesPage = lazy(
    () => import('../modules/apps/exam-attendance/ExamAttendancesPage')
  )
  const ExamPsychomotorsPage = lazy(
    () => import('../modules/apps/exam-psychomotor/ExamPsychomotorsPage')
  )
  const ExamResultsPage = lazy(() => import('../modules/apps/exam-result/ExamResultsPage'))
  const ExamSchedulesPage = lazy(() => import('../modules/apps/exam-schedule/ExamSchedulesPage'))
  const ExamsPage = lazy(() => import('../modules/apps/exam/ExamsPage'))
  const GalleryImagesPage = lazy(() => import('../modules/apps/gallery-image/GalleryImagesPage'))
  const ExpendituresPage = lazy(() => import('../modules/apps/expenditure/ExpendituresPage'))
  const GallerysPage = lazy(() => import('../modules/apps/gallery/GallerysPage'))
  const FinalResultsPage = lazy(() => import('../modules/apps/final-result/FinalResultsPage'))
  const GradesPage = lazy(() => import('../modules/apps/grade/GradesPage'))
  const HostelMembersPage = lazy(() => import('../modules/apps/hostel-member/HostelMembersPage'))
  const HostelsPage = lazy(() => import('../modules/apps/hostel/HostelsPage'))
  const LibraryMembersPage = lazy(() => import('../modules/apps/library-member/LibraryMembersPage'))
  const RoomsPage = lazy(() => import('../modules/apps/room/RoomsPage'))
  const MarksPage = lazy(() => import('../modules/apps/mark/MarksPage'))
  const NewsPage = lazy(() => import('../modules/apps/news/NewsPage'))
  const PrivilegesPage = lazy(() => import('../modules/apps/privilege/PrivilegesPage'))
  const ResultCardsPage = lazy(() => import('../modules/apps/result-card/ResultCardsPage'))
  const RolesPage = lazy(() => import('../modules/apps/role/RolesPage'))
  const SectionsPage = lazy(() => import('../modules/apps/section/SectionsPage'))
  const SchoolsPage = lazy(() => import('../modules/apps/school/SchoolsPage'))
  const SlidersPage = lazy(() => import('../modules/apps/slider/SlidersPage'))
  const AboutsPage = lazy(() => import('../modules/apps/about/AboutsPage'))
  const StudentAttendancesPage = lazy(
    () => import('../modules/apps/student-attendance/StudentAttendancesPage')
  )
  const StudentTypesPage = lazy(() => import('../modules/apps/student-type/StudentTypesPage'))
  const StudentsPage = lazy(() => import('../modules/apps/student/StudentsPage'))
  const SubjectsPage = lazy(() => import('../modules/apps/subject/SubjectsPage'))
  const SyllabussPage = lazy(() => import('../modules/apps/syllabus/SyllabussPage'))
  const ClassLecturesPage = lazy(() => import('../modules/apps/class-lecture/ClassLecturesPage'))
  const PromotionsPage = lazy(() => import('../modules/apps/promotion/PromotionsPage'))
  const IdCardsPage = lazy(() => import('../modules/apps/id-card/IdCardsPage'))

  const AssetLeaveApplicationsPage = lazy(
    () => import('../modules/apps/leave-application/LeaveApplicationsPage')
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='profile/*'
          element={
            <SuspensedView1>
              <AccountPage />
            </SuspensedView1>
          }
        />
        <Route
          path='apps/admin/academic-year/*'
          element={
            <SuspensedView>
              <AcademicYearsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/admin/account/*'
          element={
            <SuspensedView>
              <AccountsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/student-management/admission/*'
          element={
            <SuspensedView>
              <AdmissionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/academic/assignment-submission/*'
          element={
            <SuspensedView>
              <AssignmentSubmissionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/category/*'
          element={
            <SuspensedView>
              <AssetCategorysPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/item/*'
          element={
            <SuspensedView>
              <AssetItemsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/purchase/*'
          element={
            <SuspensedView>
              <AssetPurchasesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/todo/*'
          element={
            <SuspensedView>
              <AssetIssuesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/store/*'
          element={
            <SuspensedView>
              <AssetStoresPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/academic/assignment/*'
          element={
            <SuspensedView>
              <AssignmentsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/academic/class/*'
          element={
            <SuspensedView>
              <ClasssPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/book/*'
          element={
            <SuspensedView>
              <BooksPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/complain/*'
          element={
            <SuspensedView>
              <ComplainsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/ebook/*'
          element={
            <SuspensedView>
              <EbooksPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/employee-management/department/*'
          element={
            <SuspensedView>
              <DepartmentsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/employee-management/designation/*'
          element={
            <SuspensedView>
              <DesignationsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/employee-management/employee-attendance/*'
          element={
            <SuspensedView>
              <EmployeeAttendancesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/employee-management/employee/*'
          element={
            <SuspensedView>
              <EmployeesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/exam/exam-attendance/*'
          element={
            <SuspensedView>
              <ExamAttendancesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/expenditure/*'
          element={
            <SuspensedView>
              <ExpendituresPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/examresult/*'
          element={
            <SuspensedView>
              <ExamResultsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/promotion/*'
          element={
            <SuspensedView>
              <PromotionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/exam/schedule/*'
          element={
            <SuspensedView>
              <ExamSchedulesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/exam/exam/*'
          element={
            <SuspensedView>
              <ExamsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/gallery/image/*'
          element={
            <SuspensedView>
              <GalleryImagesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/gallery/*'
          element={
            <SuspensedView>
              <GallerysPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/finalresult/*'
          element={
            <SuspensedView>
              <FinalResultsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/exam/grade/*'
          element={
            <SuspensedView>
              <GradesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/id-cards/*'
          element={
            <SuspensedView>
              <IdCardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/member/*'
          element={
            <SuspensedView>
              <HostelMembersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/hostel/*'
          element={
            <SuspensedView>
              <HostelsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/library-member/*'
          element={
            <SuspensedView>
              <LibraryMembersPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/application/*'
          element={
            <SuspensedView>
              <AssetLeaveApplicationsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/room/*'
          element={
            <SuspensedView>
              <RoomsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/mark/*'
          element={
            <SuspensedView>
              <MarksPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/marksheet/*'
          element={
            <SuspensedView>
              <MarksPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/psychomotor/marksheet/*'
          element={
            <SuspensedView>
              <ExamPsychomotorsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/frontend/news/*'
          element={
            <SuspensedView>
              <NewsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/admin/permission/*'
          element={
            <SuspensedView>
              <PrivilegesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/resultcard/*'
          element={
            <SuspensedView>
              <ResultCardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/admin/role/*'
          element={
            <SuspensedView>
              <RolesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/academic/section/*'
          element={
            <SuspensedView>
              <SectionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/admin/school/*'
          element={
            <SuspensedView>
              <SchoolsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/frontend/slider/*'
          element={
            <SuspensedView>
              <SlidersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/frontend/about/*'
          element={
            <SuspensedView>
              <AboutsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/student-management/student-attendance/*'
          element={
            <SuspensedView>
              <StudentAttendancesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/student-management/student-type/*'
          element={
            <SuspensedView>
              <StudentTypesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/student-management/student/*'
          element={
            <SuspensedView>
              <StudentsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/academic/subject/*'
          element={
            <SuspensedView>
              <SubjectsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/academic/syllabus/*'
          element={
            <SuspensedView>
              <SyllabussPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/academic/class-lecture/*'
          element={
            <SuspensedView>
              <ClassLecturesPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const {pathname} = useLocation()
  const title = getTitle(pathname)
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  const {privilege} = useAuth()
  const visible = () => {
    let res = false
    const result = privilege?.find((p) => p.operation?.operation_slug === title.toLowerCase())
    if (result) res = result['is_view'] || false
    return res
  }
  return visible() ? (
    <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
  ) : (
    <Routes>
      <Route path='*' element={<Navigate to='/dashboard' />} />
    </Routes>
  )
}
const SuspensedView1: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
